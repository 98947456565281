import React, { useContext } from "react"
import * as St from "../Styled"
import { AppContext } from "../../contexts/AppContext"
import FeaturedArtists from "./FeaturedArtists"
import Artists from "./Artists"

const ContentFeaturedArtists = props => {
  let { selectedCountry } = useContext(AppContext)
  return (
    <St.ContentParent>
      <St.AdWrapper>
        <St.LeftAdSection />
        <St.LeftAd2Section />
      </St.AdWrapper>
      <St.ContentWrapper>
        {selectedCountry && selectedCountry.id && (
          <FeaturedArtists selectedCountry={selectedCountry} />
        )}
        <St.CenterContentSection>
          {selectedCountry && selectedCountry.id && (
            <Artists selectedCountry={selectedCountry} />
          )}
        </St.CenterContentSection>
        <St.BottomAdSection />
      </St.ContentWrapper>
      <St.AdWrapper>
        <St.RightAdSection />
        <St.RightAd2Section />
      </St.AdWrapper>
    </St.ContentParent>
  )
}

export default ContentFeaturedArtists

import gql from "graphql-tag"

export const QUERY_ARTIST_BY_ID_BASIC_DETAILS = gql`
  query Artist($id: ID!) {
    artist(id: $id) {
      id
      title
      image {
        id
        url
      }
      cover {
        id
        url
      }
      country {
        id
        title
      }
      location
      contact
      about
    }
  }
`

export const QUERY_ARTIST_BY_ID = gql`
  query ArtistById($id: ID!) {
    person: artist(id: $id) {
      id
      title
      image {
        id
        url
      }
      cover {
        id
        url
      }
      location
      contact
      about
      audios: mediacontents(
        sort: "updatedAt:desc"
        where: { contenttype: "audio" }
      ) {
        id
        title
        contenttype
        uri
        viewed
        image {
          id
          url
        }
        premium
        about
      }
      videos: mediacontents(
        sort: "updatedAt:desc"
        where: { contenttype: "video" }
      ) {
        id
        title
        contenttype
        uri
        viewed
        image {
          id
          url
        }
        updatedAt
        premium
        about
      }
    }
  }
`

export const QUERY_ALL_ARTISTS = gql`
  query ArtistsByCriteria(
    $offset: Int
    $max: Int
    $order: String
    $where: JSON
  ) {
    artists(start: $offset, limit: $max, sort: $order, where: $where) {
      id
      title
      image {
        id
        url
      }
      cover {
        id
        url
      }
      country {
        id
        title
      }
      location
      contact
      about
    }
  }
`
export const QUERY_FEATURED_ARTISTS_BY_COUNTRY_ID = gql`
  query ArtistsFeaturedByCountryId(
    $countryID: ID!
    $offset: Int
    $max: Int
    $order: String
  ) {
    artists(
      start: $offset
      limit: $max
      sort: $order
      where: { country: $countryID, featured: true }
    ) {
      id
      title
      image {
        id
        url
      }
      cover {
        id
        url
      }
      location
      contact
      about
    }
  }
`

export const QUERY_ARTIST_BY_ID_NAME_ONLY = gql`
  query ArtistByID($id: ID!) {
    artist(id: $id) {
      id
      title
    }
  }
`
export const QUERY_ARTIST_BY_ID_AND_VIDEOS = gql`
  query ArtistAndItsVideos($id: ID!) {
    artist(id: $id) {
      id
      title
      videos: mediacontents(
        sort: "title:asc"
        where: { contenttype: "video", artist: $id }
      ) {
        id
        title
        about
        uri
        viewed
        premium
        image {
          id
          url
        }
        artist {
          id
          title
        }
        updatedAt
      }
    }
  }
`
export const MUTATION_UPDATE_ARTIST = gql`
  mutation UpdateArtist($input: updateArtistInput) {
    updateArtist(input: $input) {
      artist {
        id
        title
        location
        image {
          id
          url
        }
        cover {
          id
          url
        }
        about
        contact
        country {
          id
        }
      }
    }
  }
`

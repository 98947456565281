import React from "react"
import styled from "styled-components"
import {
  MOBILE,
  LANDSCAPE,
  TAB,
  MEDIUM,
  LARGE,
} from "../../constants/breakpoints"
import Slider from "react-slick"

const CarouselContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  overflow: visible;
  align-self: flex-start;
  position: relative;
`
const CarouselCards = styled.div`
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.contentWidth + MOBILE.box.carouselPaddingRight}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.contentWidth + LANDSCAPE.box.carouselPaddingRight}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.contentWidth + TAB.box.carouselPaddingRight}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.contentWidth + MEDIUM.box.carouselPaddingRight}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.contentWidth + LARGE.box.carouselPaddingRight}px;
  }
`
const CarouselMain = ({ children, settings }) => {
  return (
    <CarouselContainer>
      <CarouselCards>
        <Slider {...settings}>{children}</Slider>
      </CarouselCards>
    </CarouselContainer>
  )
}

export default CarouselMain

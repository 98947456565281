import React, { useContext } from "react"
import Query from "../../Query"
import { QUERY_ALL_ARTISTS } from "../../../queries/page/artist"
import styled from "styled-components"
import StyledImage from "../../../components/StyledImage"
import * as St from "../../Styled"
import { Link } from "gatsby"
import {
  convertToSlug,
  artistDetailsURL,
  getConfigValue,
  DATA_TYPE_ARTIST,
} from "../../../constants/props"

import {LANDSCAPE} from "../../../constants/breakpoints"

import { artistDetailsShareURL } from "../../../constants/share"

import { AppContext } from "../../../contexts/AppContext"
import Carousel from "../../SliderContainer/carousel"
import ReadMorePara from "../../ReadMorePara"
import LikeAndShare from "../../LikeAndShare"

const LinkArtistPage = styled(Link)`
  cursor: pointer;
  color: #fff;
  &:hover {
    text-decoration: none;
    color: #fff;
  }
`
const LinkArtistName = styled(Link)`
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    cursor: pointer;
    color: #fff;
    &:hover {
      text-decoration: none;
      transform: scale(${LANDSCAPE.linkHover.scale}) translateX(45px);
      transition: all ${LANDSCAPE.linkHover.transition}s;
      color: ${LANDSCAPE.linkHover.color};
    }
  }
`
const LikeShare = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  vertical-align: middle;
  position: relative;
`
const FeaturedArtist = ({ device, data, selectedCountry }) => {
  const basePrefix = `${process.env.STRAPI_SERVER_URL}`
  const { id, title, about, contact, location, country } = data
  const artistImage =
    data.image && data.image.url
      ? data.image.url.startsWith("http")
        ? data.image.url
        : basePrefix + data.image.url
      : ""
  return (
    <St.ArtistFeaturedWrapper>
      <St.ArtistFeaturedImageWrapper>
        <LinkArtistPage
          to={artistDetailsURL(
            id,
            convertToSlug(title),
            selectedCountry ? selectedCountry.id : null,
            selectedCountry ? convertToSlug(selectedCountry.name) : null
          )}
        >
          <StyledImage
            image={artistImage}
            width={"100%"}
            height={"100% "}
            shaded={true}
            hover={false}
            imageAlt={title}
          />
        </LinkArtistPage>
      </St.ArtistFeaturedImageWrapper>
      <St.ArtistFeaturedRightTextSection>
        <LinkArtistName
          to={artistDetailsURL(
            id,
            convertToSlug(title),
            selectedCountry ? selectedCountry.id : null,
            selectedCountry ? convertToSlug(selectedCountry.name) : null
          )}
        >
          <St.ArtistFeaturedTitle> {title} </St.ArtistFeaturedTitle>
        </LinkArtistName>
        {location && (
          <St.ArtistFeaturedSingleLineText>
            <div>Location: </div> <span>{location}</span>
          </St.ArtistFeaturedSingleLineText>
        )}
        {contact && (
          <St.ArtistFeaturedSingleLineText>
            <div>Contact: </div> <span>{contact}</span>
          </St.ArtistFeaturedSingleLineText>
        )}

        <St.ArtistFeaturedMultiLineText>
          <div>About:</div>
          <span>
            <ReadMorePara
              string={about}
              offset={getConfigValue(device, "box.artist.featured.textOffset")}
            />
          </span>
        </St.ArtistFeaturedMultiLineText>

        <St.FeaturedSocialIconSection>
          <LikeShare>
            <LikeAndShare
              shareURL={artistDetailsShareURL(
                id,
                convertToSlug(title),
                country ? country.id : "",
                country ? convertToSlug(country.title) : "",
                artistImage
              )}
              id={id}
              shareID={"featuredartist_" + id}
              likeType={DATA_TYPE_ARTIST}
            />
          </LikeShare>
        </St.FeaturedSocialIconSection>
      </St.ArtistFeaturedRightTextSection>
    </St.ArtistFeaturedWrapper>
  )
}

const FeaturedBox = ({ artists, selectedCountry }) => {
  let { device } = useContext(AppContext)

  let settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    variableWidth: false,
    lazyLoad: false,
    arrows: false,
    autoplay: true,
    dots: true,
    autoplaySpeed: 2000,
    dotsClass: "carousel-dots",
  }

  return (
    <St.FeaturedBox>
      <St.FeaturedBoxContent>
        <St.FeaturedBoxHeading>
          FEATURED ARTIST : {selectedCountry.name}
        </St.FeaturedBoxHeading>
        <Carousel settings={settings}>
          {artists &&
            artists.map((artist, idx) => (
              <FeaturedArtist
                key={artist.id}
                data={{
                  ...artist,
                }}
                selectedCountry={selectedCountry}
                device={device}
              />
            ))}
        </Carousel>
      </St.FeaturedBoxContent>
    </St.FeaturedBox>
  )
}

// Fetches both station basic detail (station name) and featured podcasts that are needed to draw featured podcast box
// and then passed that information to next component to draw
const FeaturedArtists = ({ selectedCountry }) => {
  let criteria = { featured: true, active: true }
  if (selectedCountry && selectedCountry.id && selectedCountry.name !== "All") {
    criteria["country"] = selectedCountry.id
  }

  return (
    <Query
      query={QUERY_ALL_ARTISTS}
      variables={{
        where: criteria,
      }}
      fetchPolicy="network-only"
    >
      {({ data: { artists }, fetchMore }) => {
        return artists && artists.length ? (
          <FeaturedBox
            countryName={selectedCountry.name}
            selectedCountry={selectedCountry}
            artists={artists}
          ></FeaturedBox>
        ) : null
      }}
    </Query>
  )
}

export default FeaturedArtists

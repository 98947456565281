import React from "react"
import { useQuery } from "@apollo/client"

const Query = ({
  children,
  query,
  variables,
  fetchPolicy,
  token,
  onError,
  returnLoading,
}) => {
  let config = {
    variables,
  }

  if (fetchPolicy) {
    config["fetchPolicy"] = fetchPolicy
  }

  if (token) {
    config["context"] = {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  }
  const { data, loading, error, fetchMore, refetch } = useQuery(query, config)

  if (loading) {
    return returnLoading ? children({ loading: true }) : null
  }

  if (error) {
    if (onError) onError(error)
    return null
  }

  return children({ data, fetchMore, refetch })
}

export default Query

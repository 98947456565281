import React, { useContext } from "react"
import styled from "styled-components"
import Badge from "react-bootstrap/Badge"
import { NotificationManager } from "react-notifications"
import {
  MOBILE,
  LANDSCAPE,
  TAB,
  MEDIUM,
  LARGE,
} from "../../constants/breakpoints"

import iconHoverPlay from "../../images/player/ItemHoverImage.svg"
import iconHoverPause from "../../images/player/itemHoverPause.svg"
import { AppContext } from "../../contexts/AppContext"

const StyledPhoto = styled.div`
  position: relative;
  width: ${props => props.width}
  height: ${props => props.height}
  border-radius:${props => (props.borderRadius ? props.borderRadius : "30px")};
  cursor: pointer;
  background: #000;
  justify-content: space-around;
  display: flex;
  ${props =>
    props.shaded
      ? `&::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius:${props.borderRadius ? props.borderRadius : "30px"};
    background: linear-gradient(45deg,black 0 45%,white 45% 100%);
    opacity: 0.2;
  }`
      : ""}
  
`

const ThumbImage = styled.img`
  position: absolute;
  width: ${props => props.width}
  height: ${props => props.height}
  border-radius:${props => (props.borderRadius ? props.borderRadius : "30px")};
  cursor: pointer;
`

const PremiumBadge = styled(Badge)`
  position: absolute;
  top: 0.4rem;
  margin-left: ${props => (props.ismobile ? "0.2rem" : "0.4rem")};
  width: auto;
  line-height: 1.2em;
  text-align: center;
  vertical-align: middle;
  box-shadow: 0.28vh 0.28vh 0.46vh rgba(0, 0, 0, 0.6);
`

const HoverPlayButton = styled.div`
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  opacity: 0;
  border-radius: ${props => (props.borderRadius ? props.borderRadius : "30px")};
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${props =>
    props.playing ? iconHoverPause : iconHoverPlay});
  z-index: 1001;
  background-size: 90px;
  &:hover {
    opacity: 0.7;
  }
`
const ArtistImg = styled.img`
  box-shadow: 0px 0px 25px #000;
  border-radius: 50%;
  top: 0;
  bottom: 0;
  margin: auto;
  margin-left: 50%;
  transform: translateX(-50%);
  position: absolute;
  z-index: 2;
  width: 250px;
  height: 250px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.box.artist.details.image.widthHeight}vw;
    height: ${MOBILE.box.artist.details.image.widthHeight}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${MOBILE.box.artist.details.image.widthHeight}vw;
    height: ${MOBILE.box.artist.details.image.widthHeight}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${MOBILE.box.artist.details.image.widthHeight}vw;
    height: ${MOBILE.box.artist.details.image.widthHeight}vw;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.box.podcasts.image.widthHeight}px;
    height: ${MEDIUM.box.podcasts.image.widthHeight}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.box.podcasts.image.widthHeight}px;
    height: ${LARGE.box.podcasts.image.widthHeight}px;
  }
`
const StyledImage = ({
  image,
  premium,
  playing,
  width,
  height,
  hover = true,
  onClickImage,
  onClickPlay,
  borderRadius,
  shaded,
  profile,
  imageAlt,
}) => {
  const { userState } = useContext(AppContext)
  return (
    <StyledPhoto
      image={image}
      width={width}
      height={height}
      onClick={onClickImage}
      borderRadius={borderRadius}
      shaded={shaded}
    >
      <ThumbImage
        src={image}
        width={width}
        height={height}
        borderRadius={borderRadius}
        alt={imageAlt ? imageAlt : ""}
      ></ThumbImage>
      {hover && (
        <HoverPlayButton
          borderRadius={borderRadius}
          playing={playing}
          onClick={event => {
            if (!userState.isLoggedIn && premium) {
              NotificationManager.info("Please login to play!")
            } else {
              onClickPlay(event)
            }
          }}
        />
      )}
      {profile && <ArtistImg src={profile}></ArtistImg>}
      <PremiumBadge
        pill
        variant="danger"
        style={{
          display: premium ? "block" : "none",
        }}
      >
        Premium
      </PremiumBadge>
    </StyledPhoto>
  )
}

export default StyledImage

export const stationDetailsShareURL = slug => {
  return `/radio/${slug}`
}

export const stationPodcastsShareURL = (
  stationID = "",
  podcastSlug = "",
  stationNameSlug = "",
  countrySlug = "",
  imageUrl = ""
) => {
  return `/stationpodcasts?__sid=${stationID}&i=${imageUrl}&c=${countrySlug}&s=${stationNameSlug}&p=${podcastSlug}`
}

export const stationVideoDetailsShareURL = (
  videoID,
  videoSlug = "",
  stationID,
  stationSlug = "",
  imageUrl = ""
) => {
  return `/stationvideodetails?__sid=${stationID}&__vid=${videoID}&i=${imageUrl}&v=${videoSlug}&s=${stationSlug}`
}
export const artistDetailsShareURL = (
  artistID = "",
  artistSlug = "",
  countryID = "",
  countrySlug = "",
  imageUrl = ""
) => {
  return `/artistdetails?a=${artistSlug}&c=${countrySlug}&__cid=${countryID}&__aid=${artistID}&i=${imageUrl}`
}

export const artistPodcastsShareURL = (
  artistID = "",
  podcastSlug = "",
  artistSlug = "",
  countrySlug = "",
  imageUrl = ""
) => {
  return `/artistpodcasts?__aid=${artistID}&i=${imageUrl}&c=${countrySlug}&a=${artistSlug}&p=${podcastSlug}`
}

export const artistVideoDetailsShareURL = (
  videoID,
  videoSlug = "",
  artistID,
  artistSlug = "",
  imageUrl = ""
) => {
  return `/artistvideodetails?__aid=${artistID}&__vid=${videoID}&i=${imageUrl}&v=${videoSlug}&a=${artistSlug}`
}

import React, { useContext } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import StyledImage from "../../StyledImage"
import { TAB, LANDSCAPE } from "../../../constants/breakpoints"
import {
  artistDetailsURL,
  convertToSlug,
  getConfigValue,
  DATA_TYPE_ARTIST,
} from "../../../constants/props"
import { artistDetailsShareURL } from "../../../constants/share"
import * as St from "../../Styled"

import { AppContext } from "../../../contexts/AppContext"
import LikeAndShare from "../../LikeAndShare"
import ReadMorePara from "../../ReadMorePara"

const LinkArtistPage = styled(Link)`
  cursor: pointer;
  color: #fff;
  &:hover {
    text-decoration: none;
    color: #fff;
  }
`

const LinkArtistName = styled(Link)`
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    cursor: pointer;
    color: #fff;
    &:hover {
      text-decoration: none;
      transform: scale(${LANDSCAPE.linkHover.scale}) translateX(40px);
      transition: all ${LANDSCAPE.linkHover.transition}s;
      color: ${LANDSCAPE.linkHover.color};
    }
  }
`
const LikeShare = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  vertical-align: middle;
  position: unset;
  @media (min-width: ${TAB.minWidth}px) {
    position: relative;
  }
`

const ArtistCard = ({ artist }) => {
  const basePrefix = `${process.env.STRAPI_SERVER_URL}`
  const { selectedCountry, device } = useContext(AppContext)

  if (!artist) return null

  const { id, title, location, contact, about, country } = artist

  const image =
    artist.image && artist.image.url
      ? artist.image.url.startsWith("http")
        ? artist.image.url
        : basePrefix + artist.image.url
      : ""

  return (
    <St.ArtistCardSection>
      <LinkArtistPage
        to={artistDetailsURL(
          id,
          convertToSlug(title),
          selectedCountry ? selectedCountry.id : null,
          selectedCountry ? convertToSlug(selectedCountry.name) : null
        )}
      >
        <St.ArtistImageWrapper>
          <StyledImage
            image={image}
            width={"100%"}
            height={"100%"}
            shaded={true}
            hover={false}
            imageAlt={title}
          />
        </St.ArtistImageWrapper>
      </LinkArtistPage>
      <St.ArtistsRightInfoSection>
        <LinkArtistName
          to={artistDetailsURL(
            id,
            convertToSlug(title),
            selectedCountry ? selectedCountry.id : null,
            selectedCountry ? convertToSlug(selectedCountry.name) : null
          )}
        >
          <St.ArtistTitle> {title} </St.ArtistTitle>
        </LinkArtistName>
        {location && (
          <St.ArtistSingleLineText>
            <b>Location: </b> <span>{location}</span>
          </St.ArtistSingleLineText>
        )}
        {contact && (
          <St.ArtistSingleLineText>
            <b>Artist Contact: </b> <span>{contact}</span>
          </St.ArtistSingleLineText>
        )}
        <St.ArtistGridMultiLineText>
          <div>About:</div>
          <ReadMorePara
            string={about}
            offset={getConfigValue(device, "artists.grid.textOffset")}
          />
        </St.ArtistGridMultiLineText>

        <St.ArtistShareLikeSection>
          <LikeShare>
            <LikeAndShare
              shareURL={artistDetailsShareURL(
                id,
                convertToSlug(title),
                country ? country.id : "",
                country ? convertToSlug(country.title) : "",
                image
              )}
              id={id}
              shareID={"allartist_" + id}
              likeType={DATA_TYPE_ARTIST}
            />
          </LikeShare>
        </St.ArtistShareLikeSection>
      </St.ArtistsRightInfoSection>
    </St.ArtistCardSection>
  )
}

export default ArtistCard

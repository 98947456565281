import React, { useContext } from "react"
import ContentArtists from "../components/ContentArtists"
import queryString from "query-string"
import { navigate } from "gatsby-link"
import { AppContext } from "../contexts/AppContext"
import { artistsPageURL, convertToSlug } from "../constants/props"

const Artists = ({ location }) => {
  let { selectedCountry } = useContext(AppContext)
  const queryParams =
    location && location.search ? queryString.parse(location.search) : {}

  if (!queryParams.__cid) return <div>No such page</div>

  // if no selectCountry is yet in context this means user has directly landed to this page
  // in this case we will load selected country from server and set it as selected country
  // here selected country is just used to trigger javascript on client side as I noticed problems of styling when component does not re-render
  // onces on client side
  
  return <>{selectedCountry && <ContentArtists></ContentArtists>}</>
}

export default Artists

import React, { useState, useContext } from "react"
import { QUERY_ALL_ARTISTS } from "../../../queries/page/artist"
import { AppContext } from "../../../contexts/AppContext"
import ArtistCard from "./ArtistCard"
import Query from "../../Query"
import * as St from "../../Styled"

const AllArtists = ({ artists }) => {
  const { selectedCountry } = useContext(AppContext)
  let [sortCriteria, setSortCriteria] = useState("asc")
  const onSortChange = event => {
    setSortCriteria(event.target.value)
  }
  if (artists && artists.length > 0) {
    return (
      <St.AllCardsSection>
        <St.AllCardsTopSection>
          <St.AllCardsHeading>
            ARTISTS : {selectedCountry ? selectedCountry.name : ""}
          </St.AllCardsHeading>
          <St.SortSpan>
            SORT :
            <St.SortSelect defaultValue={sortCriteria} onChange={onSortChange}>
              <St.SortOption value="title:asc">A-Z</St.SortOption>
              <St.SortOption value="title:desc">Z-A</St.SortOption>
            </St.SortSelect>
          </St.SortSpan>
        </St.AllCardsTopSection>
        <St.AllCardsWrapper>
          {artists.map((artist, i) => (
            <ArtistCard key={i} artist={artist} />
          ))}
        </St.AllCardsWrapper>
      </St.AllCardsSection>
    )
  } else {
    return <St.NoDataMessage>
            No Artists for this country yet, please encourage local artists to sign up.
          </St.NoDataMessage>
  }
}

const ArtistsLoader = ({ selectedCountry }) => {
  let criteria = { active: true }
  if (selectedCountry && selectedCountry.id && selectedCountry.name !== "All") {
    criteria["country"] = selectedCountry.id
  }

  return (
    <Query
      query={QUERY_ALL_ARTISTS}
      variables={{
        where: criteria,
        sort: "title:asc",
      }}
      fetchPolicy="network-only"
    >
      {({ data: { artists } }) => {
        return <AllArtists artists={artists}></AllArtists>
      }}
    </Query>
  )
}

export default ArtistsLoader
